<template>
    <div class="news-container">
        <breadcrumb></breadcrumb>
        <div class="container-title">
            看·非遗
        </div>
        <div class="news-category-box">
            <category @change="changeCategory" :categorys="categorys"></category>
        </div>
        <div class="news-list-box">
            <router-link :to="'/kanfeiyi/detail/' + item.id" v-for="item in list" :key="item.id">
                <div class="news-item">
                    <div class="title-img" v-if="item.titleImg">
                        <img :src="item.titleImg" alt="">
                    </div>
                    <div class="news-info" :class="{ 'news-info-no-img': !item.titleImg }">
                        <div class="title">
                            {{ item.title }}.
                        </div>
                        <div class="description">
                            {{ item.description }}</div>
                        <div class="other">
                            <div class="time">
                                {{ formatDate(item.createTime, 'yyyy-MM-dd') }}
                            </div>
                            <div class="origin">
                                {{ item.origin }}
                            </div>
                        </div>
                    </div>
                    <!-- 小三角 -->
                    <div class="news-triangle"></div>
                </div>
            </router-link>
        </div>
        <!-- 分页组件 -->
        <pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList" />
    </div>
</template>

<script>
import {
    getNewsPage
} from "@/api/news/news";
export default {
    name: 'newsList',
    data() {
        return {
            categoryIndex: 0,//分类下标
            //资讯分类
            categorys: [{
                name: '非遗政策',
                newsType: 1
            }, {
                name: '非遗动态',
                newsType: 2
            }, {
                name: '活动通知',
                newsType: 3
            }],
            pageNo: 1,//页码
            pageSize: 10,//每页条数
            total: 0,//总数量
            list: [],//列表
        }
    },
    methods: {
        /**
         * 获取资讯列表
         */
        getList() {
            let newsType = this.categorys[this.categoryIndex].newsType//当前分类
            getNewsPage({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                newsType: newsType,//资讯类型
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list
                    this.total = res.data.total
                }
            })
        },
        /**
         * 选择分类
         */
        changeCategory(index) {
            this.categoryIndex = index
            this.getList()
        }
    },
    created() {
        //获取资讯列表
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.news-container {
    width: 1200px;
    margin: 0 auto;

    .container-title {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #181B1C;
        font-family: XiaoWei-Regular;
    }

    .news-list-box {
        width: 100%;


        .news-item {
            background-image: url('../../assets/images/news-item-white-bg.png');
            transition: .3s ease-in-out;
            width: 100%;
            height: 190px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            border: 2px solid rgba(151, 151, 151, 0.103);
            margin-bottom: 30px;
            position: relative;

            .title-img {
                width: 185px;
                height: 140px;
                border-radius: 5px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .news-info {
                width: calc(100% - 185px - 30px);
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .title {
                    transition: .3s;
                    width: 100%;
                    font-size: 16px;
                    color: #181B1C;
                    font-weight: bold;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .description {
                    width: 100%;
                    font-size: 14px;
                    color: #979797;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }


                .other {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    gap: 30px;
                    font-size: 14px;
                    color: #979797;
                }
            }

            .news-info-no-img {
                width: 100%;
            }

            .news-triangle {
                position: absolute;
                transition: .3s;
                opacity: 0;
                left: 10%;
                bottom: 0;
                width: 0;
                height: 0;
                border-right: 10px solid transparent;
                border-left: 10px solid transparent;
                border-bottom: 10px solid #3A8DA6;
                z-index: 9;
            }

            .news-triangle::before {
                content: "";
                position: absolute;
                top: 2px;
                transform: translate(-50%, 0);
                width: 0;
                height: 0;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                border-bottom: 8px solid #ffffff;
            }

            .news-triangle::after {
                content: "";
                position: absolute;
                top: 5px;
                transform: translate(-50%, 0);
                width: 0;
                height: 0;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                border-bottom: 5px solid #3A8DA6;
            }

        }

        .news-item:hover {
            background-image: url('../../assets/images/news-item-bg.png');
            border-bottom: 2px solid #3A8DA6;

            .title {
                color: #3A8DA6;
            }

            .news-triangle {
                opacity: 1;
            }

        }

    }
}
</style>